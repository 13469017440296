<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog" :persistent="loadingForm">
    <v-card>
      <v-card-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close" :disabled="loadingForm">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form :ref="formRef">
          <v-row>
            <!-- CAMPO TIPO COBERTURA -->
            <v-col cols="12">
              <v-autocomplete
                :value="form.tipo"
                :items="Coberturas"
                item-text="tipo_cobertura_desc"
                item-value="tipo_cobertura"
                label="Tipo de cobertura"
                return-object
                @change="handlerSetCobertura"
                :loading="loadingCoberturaType"
                :rules="[
                  (v) => !!v || 'Debe seleccionar un tipo de cobertura.',
                ]"
              ></v-autocomplete>
            </v-col>

            <!-- CAMPO NOMBRE COBERTURA -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.titulo"
                name="tituloCobertura"
                label="Nombre de la Cobertura"
                placeholder="Escriba título cobertura..."
                :rules="[(v) => !!v || 'El campo nombre es requerido']"
              >
              </v-text-field>
            </v-col>

            <!-- CAMPO CODIGO -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.codigo"
                label="Código"
                placeholder="Escriba el código de la cobertura..."
                :rules="[(v) => !!v || 'El campo código es requerido.']"
              >
              </v-text-field>
            </v-col>

            <!-- CAMPO DESCRIPCION -->
            <v-col cols="12">
              <v-text-field
                v-model="form.descripcion"
                name="descripcion"
                label="Descripcion"
                placeholder="Escriba la descripción de la cobertura..."
                :rules="[(v) => !!v || 'El campo descripción es requerido.']"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit" :loading="loadingForm">
          {{ labels.submit }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import FormMixin from "@/Mixins/SetFormMixin.js";

export default {
  name: "ModalCoberturasForm",
  mixins: [FormMixin],
  data() {
    return {
      loadingForm: false,
      loadingCoberturaType: false,
      defaultTitle: "Formulario de Coberturas",
      form: {
        tipo: "",
        tipo_descripcion: "",
        titulo: "",
        descripcion: "",
        codigo: "",
      },
      Coberturas: [],
    };
  },
  methods: {
    ...mapActions("NewProduct", [
      "CrearCobertura",
      "UpdateCobertura",
      "getTipoCoberturas",
    ]),

    setup() {
      this.getTipoData();
    },

    handlerSetCobertura(item) {
      this.form.tipo = item.tipo_cobertura;
      this.form.tipo_descripcion = item.tipo_cobertura_desc;
    },

    getTipoData() {
      this.loadingCoberturaType = true;
      this.getTipoCoberturas()
        .then((resp) => {
          this.Coberturas = resp.coberturas;
        })
        .finally(() => (this.loadingCoberturaType = false));
    },

    submit() {
      if (this.formComponent?.validate()) {
        this.loadingForm = true;
        let formData = {};
        let METHOD = this.CrearCobertura;
        if (this.formData) {
          METHOD = this.UpdateCobertura;
          formData.id = this.formData.cobertura;
          formData.data = { ...this.form };
        } else {
          formData = { ...this.form };
        }
        METHOD(formData)
          .then((response) => {
            this.snackbar(response.info, "success");
            this.$emit("submit");
          })
          .catch((error) => {
            this.snackbar(error.info);
          })
          .finally(() => {
            this.loadingForm = false;
          });
      }
    },
  },
};
</script>
