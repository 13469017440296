<template>
  <v-container>
    <v-card>
      <v-data-table :search="search" v-bind="bind.table">
        <template #top>
          <div class="d-flex pa-3">
            <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
            <v-divider v-bind="bind.divider"></v-divider>
            <v-spacer></v-spacer>
            <v-btn v-bind="bind.refresh" v-on="on.refresh">
              <v-icon>{{ icons.refresh }}</v-icon>
            </v-btn>
            <v-btn v-bind="bind.new" v-on="on.new">
              {{ labels.new }}
            </v-btn>
          </div>
        </template>

        <template #item.actions="{ item }">
          <v-btn v-bind="bind.actions" v-on="on.edit(item)">
            <v-icon>{{ icons.edit }}</v-icon>
          </v-btn>

          <v-btn v-bind="bind.actions" v-on="on.delete(item)" color="error">
            <v-icon>{{ icons.delete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <Modal v-model="dialog" v-bind="bind.form" v-on="on.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/Modal/ModalProduct/Modal-Cobertura";
import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "CoberturasCrudPage",
  mixins: [PageMixin],
  components: {
    Modal,
  },
  data: () => ({
    headers: [
      { text: "Tipo", value: "tipo_descripcion", align: "left" },
      { text: "Código", value: "codigo", align: "center" },
      { text: "Titulo", value: "titulo", align: "left" },
      { text: "Descripción", value: "descripcion", align: "left" },
      { text: "Deducible", value: "deducible", align: "center" },
      { text: "", value: "actions", align: "center", sortable: false },
    ],
    coberturaList: [],
  }),

  computed: {
    ...mapGetters("Coberturas", ["getItems"]),

    items() {
      return this.coberturaList;
    },
  },

  methods: {
    ...mapActions("NewProduct", ["getCobertura", "deleteCobertura"]),

    setup() {
      this.getCoberturas();
    },

    getCoberturas() {
      this.loading = true;
      this.getCobertura()
        .then((response) => {
          this.coberturaList = response.data.data.coberturas;
        })
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveItem() {
      this.closeForm();
      this.setup();
    },

    deleteItem(item) {
      this.$refs[this.refs.confirm]
        .show({
          title: "¿Eliminar cobertura?",
          description: "¿Desea eliminar definitivamente esta cobertura?",
        })
        .then((answer) => {
          if (answer) {
            this.deleteCobertura(item.cobertura)
              .then((response) => {
                this.snackbar(response.info, "success");
                this.setup();
              })
              .catch((error) => {
                this.snackbar(error.info);
              });
          }
        });
    },
  },
};
</script>
